import React, { FC } from "react";
import { SelectCountryProps } from ".";
import { TranslationKey } from "../../../translations/languages";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { getCountries } from "./countries";
import { RenderCallback } from "../../../utils/types/misc";

export type WithCountriesProps = {
  render: RenderCallback<Pick<SelectCountryProps, "countries">>;
};

export const WithCountriesDecorator: FC<WithCountriesProps> = ({ render }) => {
  const { languageCode } = useLanguageState();
  const countries = getCountries(languageCode as TranslationKey);
  return render({ countries });
};
