import React, { FC } from "react";
import styled from "styled-components";
import {CheckIcon, NextIcon, CloseIcon, OpenIcon} from "../atoms/icons";

import TextIconButton, {
  TextIconButtonProps,
} from "../atoms/button/texticonbutton";
import { HtmlRef } from "../../utils/types/misc";
import { colorThemes } from "../../utils/typography";
import { darken, lighten, transparentize } from "polished";

const { button: buttonTheme } = colorThemes;

type Actions = "select" | "selectAndNavigate" | "expand" | "submit";
type StyledTextIconButtonProps = TextIconButtonProps & { action: Actions };

const getBackgroundColor = ({ action }: StyledTextIconButtonProps) => {
  switch (action) {
    case "submit":
      return buttonTheme.secondary;
    default:
      return undefined;
  }
};

const getHoverBackgroundColor = ({ action }: StyledTextIconButtonProps) => {
  switch (action) {
    case "submit":
      return lighten(0.1, buttonTheme.secondary);
    default:
      return undefined;
  }
};

const getColor = ({ action }: StyledTextIconButtonProps) => {
  switch (action) {
    case "submit":
      return buttonTheme.primary;
    default:
      return undefined;
  }
};

const getFocusBorderColor = ({ action }: StyledTextIconButtonProps) => {
  switch (action) {
    case "submit":
      return buttonTheme.secondary;
    default:
      return undefined;
  }
};

const StyledTextIconButton = styled(
  ({
    action,
    inputRef,
    isSelected,
    ...rest
  }: StyledTextIconButtonProps & {
    inputRef?: HtmlRef<HTMLButtonElement>;
    isSelected?: boolean;
  }) => <TextIconButton ref={inputRef} {...rest} />
)`
  & {
    border-radius: ${({ action }) =>
      action === "select" ? "1.5rem" : "0.25rem"};

    & .content_right {
      visibility: ${({ isSelected }) => (isSelected ? "visible" : "hidden")};
    }

    background-color: ${getBackgroundColor};
    color: ${getColor};

    &:not([disabled]),
    &:not([aria-disabled="true"]) {
      &:hover {
        background-color: ${getHoverBackgroundColor};
        border-color: ${getFocusBorderColor};
      }
      &:active,
      &[data-active="true"] {
        color: ${getColor};
        background-color: ${getHoverBackgroundColor};
      }
    }

    &:focus,
    &:active,
    &[data-active="true"] {
      & .content_right {
        visibility: visible;
      }
      background-color: ${getHoverBackgroundColor};
      border-color: ${getFocusBorderColor};
    }
  }
`;

export type ActionButtonProps = Pick<
  TextIconButtonProps,
  "contentLeft" | "contentCenter" | "aria-label" | "onClick" | "disabled" 
> & {
  action: Actions;
  isSelected?: boolean;
  role?: string;
};



const ActionButton: FC<ActionButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ActionButtonProps
>(
  (
    { contentLeft, contentCenter, action, onClick, isSelected, ...rest },
    ref
  ) => {
    let icon: JSX.Element | null = null;
    let ariaLabel: string = "";
    switch (action) {
      case "select":
        icon = <CheckIcon />;
        ariaLabel="Check"
        break;
      case "selectAndNavigate":
        icon = <NextIcon />;
        ariaLabel = "Next";
        break;
      case "expand":
        icon = isSelected ? <CloseIcon /> : <OpenIcon />;
        ariaLabel = isSelected ? "Close" : "Open"
        break;
      case "submit":
        icon = null;
        break;
      default:
        break;
    }
    return (
      <StyledTextIconButton
        aria-label= {ariaLabel}
        action={action}
        contentLeft={contentLeft}
        contentCenter={contentCenter}
        contentRight={icon}
        onClick={onClick}
        inputRef={ref}
        isSelected={isSelected}
        type={action === "submit" ? "submit" : undefined}
        {...rest}
      />
    );
  }
);

export default ActionButton;
