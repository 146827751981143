import React from "react";
import { CSSProperties } from "styled-components";

import Icon from "../atoms/icon/index";

const style: CSSProperties= {
    fontSize: '28px'
};

const CheckIcon = () => (
    <Icon icon="icon-check" style={style}/>
);

const NextIcon = () => (
    <Icon icon="icon-chevron-right" style={style} />
);

const OpenIcon = () => (
    <Icon icon="icon-chevron-down" style={style}/>
);
const CloseIcon = () => (
    <Icon icon="icon-chevron-up" style={style}/>
);

export {
    CheckIcon,
    NextIcon,
    OpenIcon,
    CloseIcon
}