import { makeTemplate, TranslationKey } from "./languages";

type TranslationType = {
  questionsAndAnswers?: string;
  selectCountry?: string;
};

const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": {
    questionsAndAnswers: "Frequently asked questions",
    selectCountry: "Select country",
  },
  "da-DK": {
    questionsAndAnswers: "Ofte stillede spørgsmål",
    selectCountry: "Vælg land",
  },
  "fi-FI": {
    questionsAndAnswers: "Kysytyimmät kysymykset",
    selectCountry: "Valitse maa",
  },
  "is-IS": {
    questionsAndAnswers: "Algengar spurningar",
    selectCountry: "Veldu land",
  },
  "no-NO": {
    questionsAndAnswers: "Ofte stilte spørsmål",
    selectCountry: "Velg land",
  },
  "sv-SE": {
    questionsAndAnswers: "Vanligt ställda frågor",
    selectCountry: "Välj land",
  },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => translations[languageCode]?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
