import React, { FC } from "react";
import styled from "styled-components";
import { useLanguageState} from "../../../utils/contexts/languageContext";
import ActionButton from "../../molecules/actionbutton";
import { Country } from "./countries";
import { TranslationKey } from "../../../translations/languages";
import { getTranslation as getSelectCountry } from "../../../translations/questionsAnswers";
import { CountryKey } from "../../../translations/countries";
import { getTranslation } from "../../../translations/aria-labels";
import Icon from "../../atoms/icon";
import { WithCountriesDecorator } from "./decorators";

export type CountriesType = Array<Country>;

export type SelectCountryProps = {
  countries: CountriesType;
  selectedCountry?: string;
  ariaLabel?: string;
  onCountrySelected: (countryKey: CountryKey) => void;
};

type CountryItemProps = {
  country: Country;
  isSelected?: boolean;
  onCountrySelected: (countryCode: CountryKey) => void;
  languageCode: string;
};

const CountriesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
  grid-gap: 1rem;
`;

const CountryItem: FC<CountryItemProps> = ({
  country,
  isSelected,
  onCountrySelected,
  languageCode
}) => {
  const { key, icon, label} = country;
  return (
    <ActionButton
      role="menuitem"
      action="select"
      aria-label={isSelected ? `${getTranslation(languageCode as TranslationKey, "selected")} ${label}` : label}
      contentLeft={<Icon icon={icon} />}
      contentCenter={label}
      onClick={() => onCountrySelected(key)}
      isSelected={isSelected}
    />
  );
};

export const SelectCountry: FC<SelectCountryProps> = ({
  countries,
  selectedCountry,
  onCountrySelected,
  ariaLabel,
  ...rest
}) => {
  const { languageCode } = useLanguageState();
  return (
    <CountriesContainer role="menu" tabIndex={0} aria-label={ariaLabel || getSelectCountry(languageCode as TranslationKey, "selectCountry")} {...rest}>
        {countries.map((country) => {
          return (
            <CountryItem
              key={country.key}
              country={country}
              isSelected={country.key === selectedCountry}
              onCountrySelected={onCountrySelected}
              aria-label={country}
              languageCode={languageCode}
            />
          );
        })}
    </CountriesContainer>
  );
};

type WithCountriesProps = Omit<SelectCountryProps, "countries">;
const WithCountries: FC<WithCountriesProps> = (props) => (
  <WithCountriesDecorator
    render={({ countries }) => (
      <SelectCountry countries={countries} {...props} />
    )}
  />
);
export default WithCountries;
