import React, { useState, useCallback, useEffect } from "react";
import queryString from "query-string";
import { PageProps } from "gatsby";
import { Element } from "domhandler/lib/node";
import parse from "html-react-parser";

import {
  createGatsbyListLink,
  createHtmlParserOptions,
  isLinkTag,
  isPTag
} from "../utils/htmlParser";
import { SelectionType } from "../components/molecules/eitherOneSelector";
import { rootPagePath } from "../utils/path";
import { ViewpointSelectionWithContent } from "../components/organisms/viewpointSelection";
import { CountryKey } from "../translations/countries";
import BaseWrapper from "../components/molecules/BaseWrapper";

type PageContext = {
  pageId: string;
  languageCode: string;
  viewpoint: {
    a_denmark: string;
    a_finland: string;
    a_iceland: string;
    a_norway: string;
    a_sweden: string;
    b_denmark: string;
    b_iceland: string;
    b_finland: string;
    b_norway: string;
    b_sweden: string;
    country_a_header: string;
    country_b_header: string;
    viewpoint_selection_title: string;
    viewpoint_info_html?: string;
  };
};

const SelectviewpointPageTemplate = (data: PageProps) => {
  const { pageContext, navigate } = data;
  const { pageId, languageCode, viewpoint } = pageContext as PageContext;
  const { location } = data;
  const { search } = location;
  const { country: parsedCountry, viewpoint: parsedViewpoint } = queryString.parse(
    search
  );

  const pageUrl = `/${rootPagePath}${languageCode}/${pageId}`;

  const initialParserOptions = createHtmlParserOptions(languageCode);
  const parserOptions = {
    ...initialParserOptions,
    replace: (domNode) => {
      
      if (domNode instanceof Element) {
        // If element is <p>, check if there are links inside; links will have <hr> which cannot be inside <p>
        if (isPTag(domNode)) {
          if (domNode.children.find(c => isLinkTag(c))) {
            domNode.tagName = 'div'
          }
        }
        // Replace internal links with Gatsby links and add localization to the URI path.
        else if (isLinkTag(domNode)) {
          const link = createGatsbyListLink(domNode, languageCode);
          return link;
        }

        const component =
          initialParserOptions?.replace &&
          initialParserOptions.replace(domNode);
        if (component) {
          return component;
        }
      }
    },
  };
  const [selectedViewpoint, setSelectedViewpoint] = useState(
    parsedViewpoint as SelectionType || "one"
  );

  const [selectedCountry, setSelectedCountry] = useState<CountryKey | undefined>(
      parsedCountry as CountryKey
  );

  useEffect(() => {
    if(parsedCountry !== selectedCountry)
      setSelectedCountry(parsedCountry as CountryKey);
    if(parsedViewpoint !== selectedViewpoint) {
      if(parsedViewpoint == null) {
        setSelectedViewpoint("one" as SelectionType);
      } else {
        setSelectedViewpoint(parsedViewpoint  as SelectionType);
      }
    }
  }, [navigate]);

  const handleCountrySelection = useCallback(
    (countryKey: CountryKey) => {
      setSelectedCountry(countryKey);
      let newPath = `${pageUrl}/`;
      if(countryKey || selectedViewpoint)
        newPath += `?${queryString.stringify({ country: countryKey, viewpoint: selectedViewpoint })}`;
      // Prevent unneccessary navigations when entering by link
      if(`${location.pathname}${search}` !== newPath) {
        navigate(newPath);
      }
    },
    [
      navigate, location,
      languageCode,
      selectedCountry
    ]
  );

  const handleViewpointSelection = useCallback(
    (selected: SelectionType) => {
      setSelectedViewpoint(selected)
      let newPath = `${pageUrl}/`;
      if(selectedCountry || selected)
        newPath += `?${queryString.stringify({ country: selectedCountry, viewpoint: selected })}`;
      
        if(`${location.pathname}${search}` !== newPath) {
          navigate(newPath);
        }
      },
    [ navigate, location, languageCode, viewpoint]
  );

  const {
    country_a_header,
    a_denmark,
    a_finland,
    a_iceland,
    a_norway,
    a_sweden,
    country_b_header,
    b_denmark,
    b_iceland,
    b_finland,
    b_norway,
    b_sweden,
    viewpoint_selection_title,
    viewpoint_info_html,
  } = viewpoint;

  const sourceContents: Record<
    CountryKey,
    string | JSX.Element | JSX.Element[]
  > = {
    denmark: parse(a_denmark, parserOptions),
    finland: parse(a_finland, parserOptions),
    iceland: parse(a_iceland, parserOptions),
    norway: parse(a_norway, parserOptions),
    sweden: parse(a_sweden, parserOptions),
  };

  const targetContents: Record<
    CountryKey,
    string | JSX.Element | JSX.Element[]
  > = {
    denmark: parse(b_denmark, parserOptions),
    finland: parse(b_finland, parserOptions),
    iceland: parse(b_iceland, parserOptions),
    norway: parse(b_norway, parserOptions),
    sweden: parse(b_sweden, parserOptions),
  };

  const viewpointInfo = viewpoint_info_html
    ? parse(viewpoint_info_html, parserOptions)
    : undefined;

  return (
    <BaseWrapper
      crumbLabel={viewpoint_selection_title}
      SEOtitle={viewpoint_selection_title}
      location={location}>
      <ViewpointSelectionWithContent
        title={viewpoint_selection_title}
        sourceText={country_a_header}
        targetText={country_b_header}
        selectedCountry={selectedCountry}
        onCountrySelected={handleCountrySelection}
        sourceContents={sourceContents}
        targetContents={targetContents}
        selectedViewpoint={selectedViewpoint}
        onViewpointSelected={handleViewpointSelection}
        viewpointInfo={viewpointInfo}
      />
    </BaseWrapper>
  );
};

export default SelectviewpointPageTemplate;
