import { IconClassName } from "../../atoms/icon/index";
import {
  getCountryLabel,
  CountryKey,
  countries,
} from "../../../translations/countries";
import { TranslationKey } from "../../../translations/languages";

export type Country = {
  key: CountryKey;
  label: string;
  icon: IconClassName;
};

export const getCountries = (languageCode: TranslationKey): Array<Country> => {
  const result: Array<Country> = [];
  Object.keys(countries).forEach((countryKey) => {
    result.push({
      key: countryKey as CountryKey,
      label: getCountryLabel(languageCode, countryKey as CountryKey),
      icon: `icon-flag-${countryKey}` as IconClassName,
    });
  });
  return result;
};
