import { TranslationKey } from "./languages";

type TranslationType = { selected?: string, selectViewpoint?: string };
const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": {
    selected: "Selected",
    selectViewpoint: "Select your viewpoint and country"
  },
  "da-DK": {
    selected: "Valgt",
    selectViewpoint: "Vælg dit udgangspunkt og land",
  },
  "fi-FI": {
    selected: "Valittu",
    selectViewpoint: "Valitse tilanteesi ja maa"
  },
  "is-IS": {
    selected: "Valið",
    selectViewpoint: "Veldu sjónarhorn og land"
  },
  "no-NO": {
    selected: "Valgt",
    selectViewpoint: "Velg ditt utgangspunkt og land"
  },
  "sv-SE": {
    selected: "Vald",
    selectViewpoint: "Välj din utgångspunkt och land"
  },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => {
  const langObj = translations[languageCode];
  return langObj?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
};